<template>
  <form @submit.prevent="submitChangePassword" class="w-full">
    <h2 class="p-5 font-bold text-lg">Password</h2>
    <div class="m-auto border-b"></div>
    <div class="p-6">
      <div class="w-full form__div">
        <input
          class="form__input"
          type="password"
          id="password"
          placeholder=" "
          v-model="oldPassword"
          required
        />
        <label for="password" class="form__label">Password</label>
      </div>
      <div class="form__div">
        <input
          class="form__input"
          type="password"
          id="new-password"
          placeholder=" "
          v-model="newPassword"
          required
        />
        <label for="new-password" class="form__label">New password</label>
      </div>
      <div class="form__div">
        <input
          class="form__input"
          type="password"
          id="confirm-new-password"
          placeholder=" "
          v-model="confirmPassword"
          required
        />
        <label for="confirm-new-password" class="form__label">
          Confirm password
        </label>
      </div>
      <Alert :alert="alert" />
      <div class="flex items-center justify-end my-3">
        <button
          class="text-color submit-btn font-bold mt-2 px-4 py-3"
          type="submit"
        >
          Change Password
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { changePassword } from "@/services/auth/password.js";
import Alert from "@/components/general/Alert";

const oldPassword = ref(null);
const newPassword = ref(null);
const confirmPassword = ref(null);
const store = useStore();
const alert = ref({ message: "", active: false, error: false });

const userId = computed(() => store.getters["user/getUserId"]);

async function submitChangePassword() {
  if (!newPassword.value || !confirmPassword.value || !oldPassword.value) {
    handleAlert("Do not leave fields empty.", true);
    return;
  }

  if (newPassword.value !== confirmPassword.value) {
    handleAlert("Passwords do not match", true);
    return;
  }

  if (newPassword.value.length < 8) {
    handleAlert(
      "This password is too short. It must contain at least 8 characters.",
      true
    );
    return;
  }
  await handleChangePassword();
}

async function handleChangePassword() {
  const response = await changePassword(
    oldPassword.value,
    newPassword.value,
    userId.value
  );

  if (!response.success) {
    if (response.wrongPassword) {
      handleAlert("Wrong password, please try again!", true);
      return;
    }
  }
  oldPassword.value = "";
  newPassword.value = "";
  confirmPassword.value = "";
  handleAlert("Password changed", false);
}

function handleAlert(message, error) {
  alert.value = { message, active: true, error };
  setTimeout(() => {
    alert.value.active = false;
  }, 3000);
}
</script>
